import User from "./User";
import { Visibility } from "./Visibility";
import * as meta from "../AppType";

export enum OrderFieldVisibilityType {
  Include = 'include',
  Exclude = 'exclude'
}

export class OrderFieldVisibility {
  readonly type: OrderFieldVisibilityType;
  readonly fieldIds: string[] = [];

  constructor(deriveFrom?: Partial<OrderFieldVisibility>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }
  }

  isVisible(fieldId: string) {
    if (this.type === OrderFieldVisibilityType.Exclude) {
      return !this.fieldIds.includes(fieldId);
    }

    if (this.type === OrderFieldVisibilityType.Include) {
      return this.fieldIds.includes(fieldId);
    }

    return true;
  }
}

/**
 * Represents a product category, which is a container of products.
 */
export default class ProductCategory {
  readonly id: string = null;
  readonly name: string = null;
  
  /**
   * Null means visible for everyone.
   */
  readonly visibility?: Visibility;

  /**
   * Null means all order fields are visible.
   */
  readonly orderFieldVisibility?: OrderFieldVisibility;
  
  /**
   * List of hostnames of express flows in which to list products from this category.
   */
  readonly expressFlowHostnames: string[] | null = [];
  
  /**
   * Null means any project or none.
   */
  readonly projectIds?: string[] = null;

  constructor(deriveFrom?: Partial<ProductCategory>) {
    if (!deriveFrom) {
      return;
    }

    Object.assign(this, deriveFrom);

    if (this.visibility) {
      this.visibility = new Visibility(this.visibility);
    }

    if (this.orderFieldVisibility) {
      this.orderFieldVisibility = new OrderFieldVisibility(this.orderFieldVisibility);
    }
  }

  isOrderFieldVisible(fieldId: string) {
    return !this.orderFieldVisibility || this.orderFieldVisibility.isVisible(fieldId);
  }

  isVisibleInExpressFlow(hostname = meta.hostname) {
    return this.expressFlowHostnames?.includes(hostname);
  }

  isVisible(currentUser: User) {
    return !this.visibility || this.visibility.isVisible(currentUser);
  }
}
