const listeners: { [eventType: string]: ((this: Document, ev: DocumentEventMap[keyof DocumentEventMap]) => any)[]; } = {};

const handledTypes: (keyof DocumentEventMap)[] = [
  "click",
  "keyup"
];

for (let type of handledTypes) {
  document.addEventListener(type, event => handleEvent(type, event), { capture: true });
}

/**
 * Inserts an event listener at a specified index.
 * The event listener will be added with the `capture` flag set to true.
 * 
 * Using this method in favor of `document.body.addEventListener` allows you to adding event listeners with the `capturing`,
 * but prioritizing a listener over another.
 */
export function insertEventListener(type: keyof DocumentEventMap, listener: (this: Document, ev: DocumentEventMap[keyof DocumentEventMap]) => any, index: number) {
  if (!listeners[type]) {
    listeners[type] = [];
  }

  listeners[type].splice(index, 0, listener);
}

/**
 * Removes an event listener previously added by calling `insertEventListener`.
 */
export function removeIndexedEventListener(type: keyof DocumentEventMap, listener: (this: Document, ev: DocumentEventMap[keyof DocumentEventMap]) => any) {
  const index = listeners[type]?.indexOf(listener);
  if (index == null || index === -1) {
    console.warn('Could not remove listener', removeIndexedEventListener, 'as it has not been added via `insertEventListener`');
    return;
  }

  listeners[type].splice(index, 1);
}

/**
 * Handles an event by dispatching it to `listeners` until it has stopped propagation. 
 */
function handleEvent<K extends keyof DocumentEventMap>(type: K, event: DocumentEventMap[K]) {
  const intialStopPropagation = Event.prototype.stopPropagation;

  let isPropagationStopped = false;
  //  There is no native way to check stopped propagation, see https://stackoverflow.com/q/22112717
  Event.prototype.stopPropagation = function () {
    isPropagationStopped = true;
    intialStopPropagation.apply(this, arguments);
  };

  for (let listener of listeners[type] || []) {
    listener.bind(document)(event);

    if (isPropagationStopped) {
      break;
    }
  }

  Event.prototype.stopPropagation = intialStopPropagation;
}