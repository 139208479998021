import React, { CSSProperties } from 'react'
import cls from 'classnames'
import TaskIndicator from '../task_indicator';
import { Link } from 'react-router-dom';
import FontAwesomeIcon from '../fa_icon';
import styles from './Button.module.scss';

export type ButtonSize = 'regular' | 'compact';
//  "simple" is similar to "normal".
//  "simple" is only relevant with custom themes, as the purpose of "simple" is to
//  "accent" forces a dark colored button, used on light backgrounds which are unaffected by the current theme.
//  retain a simple style when the custom theme alters the normal weight's style. 
export type ButtonWeight = 'primary' | 'normal' | 'dimmed' | 'danger' | 'simple' | 'accent';

export interface ButtonWeightProps {
  weight?: ButtonWeight;
}

export interface ButtonIconProps {
  /**
   * Either the name of a font awesome icon, or a react element.
   */
  icon?: string | React.ReactNode;
  iconPosition?: 'before' | 'after';
  iconSize?: 'normal' | 'large' | 'medium' | 'xlarge';
  iconType?: 'regular' | 'solid';
  iconClassName?: string;
  iconStyle?: CSSProperties;
}

export interface ButtonProps extends Omit<React.InputHTMLAttributes<HTMLButtonElement | HTMLLabelElement>, 'size'>, ButtonIconProps, ButtonWeightProps {
  weight?: ButtonWeight;
  iconType?: 'regular' | 'solid';
  testId?: string;

  /**
   * Children which appears before any other children.
   */
  before?: React.ReactNode;
  /**
   * Children which appears after any other children.
   */
  after?: React.ReactNode;

  /**
   * Useful if wrapping a file input.
   */
  labelRoot?: boolean;

  /**
   * Whether to show a small badge.
   */
  showBadge?: boolean;

  /**
   * Whether to offset badge in case of narrow buttons.
   */
  offsetBadge?: boolean;

  showTaskIndicator?: boolean;
  linkTo?: string;
  isDark?: boolean;

  /**
   * Whether to simulate an active state.
   * Can be used when hovering a button (for convenience) acts as if the button was clicked.
   */
  active?: boolean;

  /**
   * Href to open in a new tab.
   */
  externalLinkTo?: string;
  externalLinkTarget?: string;
  size?: ButtonSize;
  textStyle?: CSSProperties;
  textClassName?: string;
}

/**
 * Standard button.
 */
export const Button: React.FC<ButtonProps> = ({
  children,
  iconPosition = 'after',
  offsetBadge,
  showBadge,
  before,
  after,
  weight = 'normal',
  textClassName,
  textStyle,
  ...props
}) => (
  <ButtonRoot {...props} weight={weight} isIconOnly={Boolean(!children && props.icon)}>
    {before}
    {
      iconPosition === "before" ? (
        <ButtonIcon
          weight={weight}
          {...props} />
      ) : null
    }
    {
      children ? (
        <div style={textStyle} className={cls([styles.text, textClassName])}>
          {children}
        </div>
      ) : null
    }
    {
      iconPosition !== "before" ? (
        <ButtonIcon
          weight={weight}
          {...props} />
      ) : null
    }
    {after}
    {
      showBadge ? (
        <div className={cls([styles.badge, offsetBadge ? styles.offset : null])} />
      ) : null
    }
  </ButtonRoot>
);

/**
 * Top element of the button. Depending on props, can render a label, button, or an anchor.
 */
const ButtonRoot: React.FC<ButtonProps & { isIconOnly: boolean; }> = ({
  labelRoot,
  testId,
  children,
  externalLinkTo,
  externalLinkTarget = '_blank',
  icon,
  iconSize,
  size = "regular",
  weight = "normal",
  className,
  active,
  isIconOnly,
  disabled,
  onClick,
  ...props
}) => {
  if (disabled) {
    onClick = null;
  }

  const componentProps: any = {
    ...props,
    onClick,
    "aria-disabled": disabled, // Setting disabled attribute does not seem to trigger onMouseLeave which causes tooltip to get stuck.
    "data-testid": testId,
    "data-size": size,
    "data-icon-size": iconSize,
    "data-weight": weight,
    className: cls([
      styles.button,
      disabled ? styles.disabled : null,
      active ? styles.active : null,
      className,
      isIconOnly ? styles.iconOnly : null,
    ])
  }

  if (labelRoot && !disabled) {
    return (
      <label {...componentProps}>{children}</label>
    );
  }

  if (typeof props.linkTo === "string") {
    return (
      <Link {...componentProps} to={!disabled ? props.linkTo : '#'}>{children}</Link>
    );
  }

  if (typeof externalLinkTo === "string") {
    return (
      <a {...componentProps} target={externalLinkTarget} rel="noopener noreferrer" href={externalLinkTo}>
        {children}
      </a>
    );
  }

  return (
    <button {...componentProps}>{children}</button>
  );
}

/**
 * Button icon.
 */
const ButtonIcon: React.FC<ButtonProps> = ({
  showTaskIndicator,
  icon,
  iconClassName,
  iconStyle,
  iconType,
  weight,
  isDark
}) => {
  if (showTaskIndicator) {
    return (
      <TaskIndicator
        animated
        type="tiny"
        className={styles.taskIndicator} />
    );
  }

  if (!icon) {
    return null;
  }

  if (React.isValidElement(icon)) {
    return React.cloneElement(icon, {
      className: cls([iconClassName, styles.icon, isDark ? styles.dark : null]),
      style: iconStyle
    })
  }

  return (
    <FontAwesomeIcon
      className={cls([iconClassName, styles.icon, isDark ? styles.dark : null])}
      style={iconStyle}
      name={icon as string}
      type={iconType} />
  );
}