import { apis } from "../../../backend";
import { getUserId } from "../../../utils/user";

/**
 * @param username Username to check.
 * @returns Whether a given username is unique and may be changed to.
 */
export async function checkUsernameAvailable(username: string) {
  const { isAvailable } = await apis.users.get<{ isAvailable: boolean; }>('/usernames-v1/availability', { username });
  return isAvailable;
}

/**
 * Requests to change the current user's username.
 * @param newUsername The new username.
 * @returns The resolved username.
 */
export async function changeUsername(newUsername: string): Promise<string> {
  const { newUsername: resolvedUsername } =
    await apis.users.put('/usernames-v1/username', { username: newUsername });

  return resolvedUsername;
}

/**
 * Redirects to the logout page, removing any access cookies.
 */
export async function logout() {
  const userId = getUserId();

  if (userId) {
    //  There will be a log spam with userId = undefined after logging out from another tab.
    console.log("Logging out from %s", userId);
  }

  if (!userId && document.location.pathname.startsWith('/login')) {
    //  Already logged out.
    return;
  }

  //  Hard redirect to ensure that app states are reset.
  //  Some 'unload' event handlers requires access token, so instead of unsetting the
  //  access token immediately, we unset it on page load, using the 'loggedOut' flag.
  window.location.href = '/login?loggedOut';
}