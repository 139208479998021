import React, { Suspense, useEffect } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { AppType, appType } from './AppType';
import { hasCredentials } from './components/core/gate_keeper';
import TaskIndicator from './components/core/task_indicator';
import { OfferSignedDocumentView } from './components_offer/OfferSignedDocumentView/OfferSignedDocumentView';
import { useLocation } from 'react-router';

/**
 * Routes to one of several user interfaces, such as main, login, or express flow.
 * The resolved UI is lazily loaded.
 */
export const RootApp: React.FC = () => {
  if (appType === AppType.OfferDocument) {
    return (
      <OfferSignedDocumentView />
    )
  }

  if (appType === AppType.OfferWebForm) {
    const LazyOfferWebFormApp = React.lazy(() => import('./components_offer_web_form/OfferWebFormApp')
      .then(({ OfferWebFormApp }) => ({ default: OfferWebFormApp })));

    return (
      <AppSuspense><LazyOfferWebFormApp /></AppSuspense>
    )
  }

  if (appType === AppType.RegistrationLink) {
    const LazyRegistrationLinkApp = React.lazy(() => import('./components_registrationlink/RegistrationLinkApp')
      .then(({ UserLinkApp }) => ({ default: UserLinkApp })));

    return (
      <AppSuspense><LazyRegistrationLinkApp /></AppSuspense>
    )
  }

  if (appType === AppType.Offer) {
    const LazyOfferApp = React.lazy(() => import('./components_offer/LinkedOfferApp')
      .then(({ LinkedOfferApp: OfferApp }) => ({ default: OfferApp })));

    return (
      <BrowserRouter>
        <AppSuspense><LazyOfferApp /></AppSuspense>
      </BrowserRouter>
    )
  }

  if (appType === AppType.Test) {
    const LazyTestApp = React.lazy(() => import('./components_test/TestView')
      .then(({ TestView }) => ({ default: TestView })));
    return (
      <AppSuspense><LazyTestApp /></AppSuspense>
    )
  }

  if (appType === AppType.ExpressFlow) {
    const LazyExpressFlowApp = React.lazy(() => import('./components_expressflow/ExpressFlowApp')
      .then(({ ExpressFlowApp }) => ({ default: ExpressFlowApp })));

    return (
      <AppSuspense><LazyExpressFlowApp /></AppSuspense>
    )
  }

  if (appType === AppType.DashboardLink) {
    const LazyLinkedDashboardApp = React.lazy(() => import('./LinkedDashboardApp/LinkedDashboardApp')
      .then(({ LinkedDashboardApp }) => ({ default: LinkedDashboardApp })));

    return (
      <AppSuspense><LazyLinkedDashboardApp /></AppSuspense>
    )
  }

  const LazyAdminApp = React.lazy(() => import('./AdminApp').then(({ AdminApp }) => ({ default: AdminApp })));
  const LazyLoginApp = React.lazy(() => import('./LoginApp').then(({ LoginApp }) => ({ default: LoginApp })));
  const LazyMainApp = React.lazy(() => import('./MainApp').then(({ MainApp }) => ({ default: MainApp })));

  return (
    <BrowserRouter>
      <LocationLogger />
      <Switch>
        <Route path="/login" render={() => (
          <AppSuspense><LazyLoginApp /></AppSuspense>
        )} />

        <Route path="/" render={() => {
          if (!hasCredentials()) {
            //  Hard redirect to ensure that app states are reset.
            window.location.href = "/login";
            return null;
          }

          return (
            <AppSuspense>
              {
                appType === AppType.Admin ? (
                  <LazyAdminApp />
                ) : (
                  <LazyMainApp />
                )
              }
            </AppSuspense>
          );
        }} />
      </Switch>
    </BrowserRouter>
  )
}

const AppSuspense: React.FC = ({ children }) => {
  return (
    <Suspense fallback={(<TaskIndicator center type="normal" />)}>
      {children}
    </Suspense>
  )
}

const LocationLogger: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    //  For debugging.
    console.log("Navigated to %s", location.pathname);
  }, [location.pathname]);

  return null;
}