import cls from "classnames";
import React, { useEffect, useState } from "react";
import { Button } from "../../components/core/Button/Button";
import { Field } from "../../components/core/field";
import Placeholder, { PlaceholderIcon } from "../../components/core/placeholder";
import { TransitionedContainer } from "../../components/core/transitioned_container";
import { hidePreload } from "../../preload";
import { map } from "../../utils";
import validator from "validator";
import styles from "./OfferSignedDocumentView.module.scss";
import { retry } from "../../utils/async";
import { OfferFromToken, addEmailNotificationRecipient, getOfferDocument, getOfferFromToken } from "./service";
import ActionNotification from "../../components/core/ActionNotification/ActionNotification";
import ErrorScreenWrapper from "../../components/core/error_screen_wrapper";
import Image from "../../components/core/image";
import { Language, setLanguage, text } from "../../utils/i18n";
import TaskIndicator from "../../components/core/task_indicator";
import { I18nLoader } from "../../I18nLoader";

/**
 * View for getting a signed document, usually with a pin code.
 */
export const OfferSignedDocumentView: React.FC = () => {
  const [pin, setPin] = useState('');
  const [offer, setOffer] = useState<OfferFromToken | null>(null);
  //  On the Android, if the link ends with a period it can get picked up as a part of the URL.
  const [version, token] = document.location.pathname.replace(/(^\/)|(\/$)/, '').replace(/\./g, '').split('/');
  const [isInaccessible, setInaccessible] = useState(!token || isNaN(version as any));

  const isPinRequired = version !== "1";

  useEffect(() => {
    if (isPinRequired || isInaccessible) {
      hidePreload();
    } else {
      retry(() => getOfferFromToken(token, undefined)
        .then(offer => {
          setLanguage(offer.country as Language);
          setOffer(offer);
        }))
        .catch(() => setInaccessible(true))
        .finally(() => hidePreload());
    }
  }, []);

  return (
    <ErrorScreenWrapper>
      <I18nLoader>
        {
          isInaccessible ? (
            <Placeholder absolute>
              <PlaceholderIcon name="question" type="solid" />
              {text`Avtalskopian är otillgänglig`}
            </Placeholder>
          ) : (
            <TransitionedContainer
              lock={isPinRequired ? Boolean(offer) : 1} // Only transition if PIN is required.
              style={{ width: '100%', height: '100%' }}
              transitionContainerStyle={{ width: '100%', height: '100%' }}
              transition="slide-left">
              {
                offer ? (
                  <DocumentView
                    token={token}
                    pin={pin}
                    offer={offer}
                  />
                ) : isPinRequired ? (
                  <PinView
                    token={token}
                    pin={pin}
                    setPin={setPin}
                    onCorrectPin={offer => setOffer(offer)}
                  />
                ) : (
                  <TaskIndicator center />
                )
              }
            </TransitionedContainer>
          )
        }

        <ActionNotification global />
      </I18nLoader>
    </ErrorScreenWrapper >
  )
}

type DocumentViewProps = {
  pin: string;
  token: string;
  offer: OfferFromToken;
}

export const DocumentView: React.FC<DocumentViewProps> = ({ pin, token, offer }) => {
  const [isAddingEmail, setAddingEmail] = useState(false);
  const [documentBlobUrl, setDocumentBlobUrl] = useState<string | null>(null);
  const [email, setEmail] = useState('');
  const [isEmailFinished, setEmailFinished] = useState(false);

  useEffect(() => {
    retry(() => getOfferDocument(token, pin)
      .then(blob => setDocumentBlobUrl(URL.createObjectURL(blob))))
  }, []);

  async function addEmail() {
    if (isAddingEmail) {
      return;
    }

    setAddingEmail(true);

    let ok = false;
    try {
      await addEmailNotificationRecipient(token, pin, email);
      ok = true;
    } catch (error) {
      console.error("Error adding email: %s", email);
      ActionNotification.showError(error);
    }

    setAddingEmail(false);

    if (ok) {
      setEmailFinished(true);
    }
  }

  const isEmailValid = email && validator.isEmail(email);

  return (
    <div className={styles.view}>
      {
        offer.issuer.logo ? (
          <Image
            src={offer.issuer.logo.uri}
            style={{ height: 48 }} />
        ) : null
      }
      <Placeholder size="small">
        {text`Avtal ${offer.serialId} från ${offer.issuer.name}`}<br />
        {text`Ladda ner eller få dokumentet på epost.`}
      </Placeholder>
      <Button
        icon="file-download"
        disabled={!documentBlobUrl}
        showTaskIndicator={!documentBlobUrl}
        externalLinkTo={documentBlobUrl}>
        {text`Ladda ner PDF`}
      </Button>

      <TransitionedContainer
        lock={isEmailFinished}
        transitionContainerStyle={{ width: '100%' }}
        style={{ width: '100%' }}
        overflowHiddenDuringTransition={false}>
        <form
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12 }}
          onSubmit={event => {
            addEmail();
            event.preventDefault();
            return false;
          }}>
          {
            isEmailFinished ? (
              <>
                <Placeholder size="small">
                  {text`Avtalskopia skickas till`}:<br />{email.trim()}
                </Placeholder>

                <Button
                  size="compact"
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();

                    setEmailFinished(false);
                    setEmail('');

                    return false;
                  }}>
                  {text`Lägg till annan epost`}
                </Button>
              </>
            ) : (
              <>
                <Field
                  containerClassName={styles.stretchedField}
                  label={text`Epost`}
                  maxLength={100}
                  value={email}
                  onTextValueChange={email => setEmail(email)}
                  placeholder={text`[example email] john@exempel.se`}
                  enterKeyHint="done"
                />

                <Button
                  icon="paper-plane"
                  showTaskIndicator={isAddingEmail}
                  weight={isEmailValid ? "primary" : "normal"}
                  disabled={!isEmailValid || isAddingEmail}>
                  {text`Skicka med epost`}
                </Button>
              </>
            )
          }
        </form>
      </TransitionedContainer>
    </div>
  )
}

type PinViewProps = {
  token: string;
  pin: string;
  setPin: React.Dispatch<React.SetStateAction<string>>;
  onCorrectPin: (offer: OfferFromToken) => void;
}

const PinView: React.FC<PinViewProps> = ({ setPin, pin, token, onCorrectPin }) => {
  const [isSubmittingPin, setSubmittingPin] = useState(false);
  const [incorrectPinCount, setIncorrectPinCount] = useState(0);

  async function submitPin() {
    if (isSubmittingPin) {
      return;
    }

    setSubmittingPin(true);

    try {
      const offer = await getOfferFromToken(token, pin);

      setIncorrectPinCount(0);
      onCorrectPin(offer);
    } catch (error) {
      console.error("Error submitting pin %s", pin, error);
      setIncorrectPinCount(count => count + 1);
    }

    setSubmittingPin(false);
  }

  useEffect(() => {
    if (pin.length === 4) {
      submitPin();
    }
  }, [pin.length]);

  return (
    <div className={styles.view}>
      <div
        key={incorrectPinCount} // Causes the shake animation to re-play.
        className={cls([styles.pinList, incorrectPinCount > 0 ? styles.incorrect : null])}>
        <div style={{ width: 32 }}></div> {/* Compensates for the erase button, aligns the pin list to the center. */}

        {
          map(4, index => (
            <div
              key={index}
              className={styles.item}>
              {pin[index]}
            </div>
          ))
        }

        <Button
          icon="backspace"
          iconSize="medium"
          showTaskIndicator={isSubmittingPin}
          disabled={!pin.length || isSubmittingPin}
          onClick={() => setPin(pin => pin.substring(0, pin.length - 1))} />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
        <Placeholder size="small">
          {incorrectPinCount > 0 ? text`Fel pinkod, försök igen.` : text`Ange pinkod för att öppna dokumentet.`}
        </Placeholder>
        <div className={cls([styles.numberPad, isSubmittingPin ? styles.disabled : null])}>
          {
            map(10, index => {
              const digit = (index + 1) % 10;

              return (
                <div
                  key={digit}
                  role="button"
                  className={styles.item}
                  onClick={() => setPin(pin => {
                    if (pin.length >= 4) {
                      return pin;
                    }

                    return pin + String(digit);
                  })}
                  style={digit === 0 ? { gridColumn: '2 / span 1' } : null}>
                  {digit}
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}