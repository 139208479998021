import React from 'react';
import * as PropTypes from 'prop-types';
import cls from 'classnames';
import FontAwesomeIcon from '../fa_icon';
import styles from "./ActionNotification.module.scss";
import { getEstimatedTimeToReadString } from '../../../utils/semantic';
import { isEventFromCurrentTarget } from '../../../utils/dom';
import { text } from '../../../utils/i18n';

/**
 * Shared notifications for easy access.
 */
let shared: ActionNotification[] = []

type Feeling = 'none' | 'ok' | 'bad' | 'sent';
function getIcon(feeling: Feeling): { name: string; type: 'solid' | 'regular' } {
  switch (feeling) {
    case 'ok':
      return { name: 'check', type: 'solid' };

    case 'sent':
      return { name: 'paper-plane', type: 'regular' };

    case 'none':
    case 'bad':
    default:
      return null;
  }
}

interface Props {
  /**
   * Whether to make this notification accessible from anywhere.
   */
  global?: boolean;
}

class NotificationDisplay {
  message: string = '';
  feeling?: Feeling;
}

class State {
  queue: NotificationDisplay[] = [];
  current: NotificationDisplay;
  visible: boolean = false;
}

export type HideNotificationCallback = () => void;

/**
 * Briefs the user of the result of an action by the user.
 */
export default class ActionNotification extends React.Component<Props, State> {
  state = new State();
  shownAt: Date;
  minShowTimeInSeconds = 2.15;

  static propTypes = {
    shared: PropTypes.bool
  }

  /**
   * @returns All shared and mounted notification instances.
   */
  static get shared() {
    return shared;
  }

  /**
   * Shows a message with the first shared component, if one exists.
   * @param holdWithCloseCallback if present, keep the notification open until the callback is invoked
   */
  static show(
    message: string,
    feeling?: Feeling,
    hold = false
  ): HideNotificationCallback {
    let instance = shared[0];
    if (instance) {
      return instance.show(message, feeling, getEstimatedTimeToReadString(message), hold);
    }
  }

  static showError(error: any) {
    return ActionNotification.show(
      error?.localizedMessage ||
      error?.validationMessage ||
      text`Något gick snett, prova igen senare`,
      'bad'
    );
  }

  animationDuration = 250;
  hideTimeoutId: any = -1;

  /**
   * @returns Time since `show` was invoked, in seconds.
   */
  get showTime() {
    if (!this.shownAt) {
      return 0;
    }
    return (Date.now() - this.shownAt.getTime()) / 1000;
  }

  componentDidMount() {
    if (this.props.global) {
      shared.push(this);
    }
  }

  componentWillUnmount() {
    shared = shared.filter(n => n != this);
  }

  /**
   * Shows a notification message.
   */
  show(
    message: string,
    feeling?: Feeling,
    duration?: number,
    hold = false
  ): HideNotificationCallback {
    if (duration == null) {
      duration = getEstimatedTimeToReadString(message);
    }

    if (this.shownAt && this.showTime < this.minShowTimeInSeconds) {
      //  Wait for previous message
      setTimeout(() => this.show(message, feeling, duration, hold), this.minShowTimeInSeconds - this.showTime);
      return;
    }

    const display: NotificationDisplay = { message, feeling };

    let { state } = this;
    if (state.visible) {
      this.setState(s => ({
        queue: [...s.queue, display]
      }));
      return;
    }

    this.setState({
      current: display,
      visible: true
    });

    this.shownAt = new Date();

    if (!hold) {
      clearTimeout(this.hideTimeoutId);
      this.hideTimeoutId = setTimeout(() => this.hide(), duration);
    } else {
      return () => {
        this.hide()
      }
    }
  }

  hide() {
    this.setState({
      visible: false
    });
  }

  onHidden() {
    const { state } = this;
    const queue = [...state.queue];
    if (queue?.length) {
      const next = queue.splice(0, 1)[0];

      this.show(next.message, next.feeling);

      this.setState({ queue });
    }
  }

  render() {
    let { state, props } = this;

    const icon = getIcon(state.current?.feeling);

    return (
      <div className={cls([styles.notification, state.visible ? styles.visible : null])}
        style={{ transitionDuration: `${this.animationDuration}ms` }}
        onTransitionEnd={event => {
          if (isEventFromCurrentTarget(event) && !state.visible) {
            this.onHidden();
          }
        }}>
        {
          icon ? (<FontAwesomeIcon {...icon} className={styles.icon} />) : null
        }
        <p className={styles.text}>{state.current?.message}</p>
      </div>
    )
  }
}
