import Comment from "./Comment";
import { OfferSignMethod } from "./OfferTemplate";
import UploadedFile from "./UploadedFile";
import { createTemporaryId, isCreated } from "../utils/model";
import Issuer from "./Issuer";
import { getProductTotal, ProductReference, ProductTotal } from "./ProductReference";
import { BasicFieldReference } from "./BasicField";
import { OfferStatusLinks } from "./OfferStatusLinks";
import { Country } from "./Country";
import { text } from "../utils/i18n";
import { OfferTemplateInsert, OfferTemplateInsertType } from "./OfferTemplateInsert";

export class OfferAttachedFile extends UploadedFile {
  readonly page?: number;
  readonly pageCount?: number;
}

export enum OfferUnsignedDocumentStatus {
  Waiting = 'waiting',
  Ready = 'ready',
  Error = 'error'
}

/**
 * Represents an offer.
 */
export default class Offer {
  readonly id: string;
  readonly key = createTemporaryId();
  readonly serialId: number;
  readonly publicId: string = null;
  readonly templateId: string;
  readonly inserts: OfferInsertReference[] = [];
  readonly templateInserts: OfferTemplateInsert[] = [];
  readonly date: Date;
  readonly recipient?: OfferMainRecipient;
  readonly signature?: OfferPreviousSignature;
  readonly read?: OfferRead;
  readonly distributions: OfferDistribution[] = [];
  readonly comments: Comment[] = [];
  readonly files: OfferAttachedFile[] = [];
  readonly status: OfferStatus = OfferStatus.Pending;
  readonly products: ProductReference[] = [];
  readonly issuer = new Issuer();
  readonly userId: string;
  readonly contactId?: string;
  readonly orderIds: string[];
  readonly decline?: OfferDecline;
  readonly cancellation?: OfferCancellation;
  readonly isTest?: boolean;
  readonly expireAt?: Date;
  readonly bankIdSession?: OfferBankIDSignSession;
  readonly country: Country = Country.Sweden;
  /**
   * @deprecated Use pending orders instead.
   */
  readonly fields?: BasicFieldReference[]; // Field references from insert references will be automatically created if undefined.
  readonly createdFrom?: string;
  readonly total: ProductTotal;
  readonly statusLinks?: OfferStatusLinks;
  readonly projectId?: string = null;
  readonly unsignedDocument?: { url?: string; status?: OfferUnsignedDocumentStatus; };

  /**
   * Overridden sign method. Should default to template's sign method.
   */
  readonly signMethod?: OfferSignMethod;
  private fieldValues: { [fieldId: string]: string } = null;

  constructor(deriveFrom?: Partial<Offer>) {
    if (deriveFrom) {
      const _deriveFrom = deriveFrom as any;
      delete _deriveFrom.isCanceled;
      delete _deriveFrom.isDistributed;
      delete _deriveFrom.isExpired;

      Object.assign(this, deriveFrom);
    }

    if (this.date) {
      this.date = new Date(this.date);
    }

    if (this.signature) {
      this.signature = new OfferPreviousSignature(this.signature);
    }

    if (this.recipient) {
      this.recipient = new OfferMainRecipient(this.recipient);
    }

    if (this.read) {
      this.read = new OfferRead(this.read);
    }

    if (this.distributions) {
      this.distributions = this.distributions.map(distribution =>
        new OfferDistribution(distribution));
    }

    if (this.comments) {
      this.comments = this.comments.map(comment => new Comment(comment));
    }

    if (this.expireAt) {
      this.expireAt = new Date(this.expireAt);
    }

    if (this.products) {
      this.products = this.products.map(reference => new ProductReference(reference));
    }

    const summedProducts = [...this.products];

    if (this.statusLinks) {
      this.statusLinks = new OfferStatusLinks(this.statusLinks);
    }

    this.total = getProductTotal(summedProducts);

    if (this.templateInserts) {
      this.templateInserts = this.templateInserts.map(insert => new OfferTemplateInsert(insert));
    }
  }

  get isCreated() {
    return isCreated(this.id);
  }

  /**
   * @returns Whether this offer has been signed.
   */
  get isSigned() {
    return !!this.signature;
  }

  /**
   * @returns Whether this offer has been read.
   */
  get isRead() {
    return !!this.read;
  }

  /**
   * @returns Whether this offer has been distributed.
   */
  get isDistributed() {
    return this.distributions?.length > 0;
  }

  /**
   * @returns Whether this offer has been canceled.
   */
  get isCanceled() {
    return this.cancellation != null;
  }

  get attachments() {
    return this.files.filter(file => typeof file.page === 'number');
  }

  /**
   * @param fieldId Order field ID.
   * @returns A field value given a field ID.
   */
  getFieldValue(fieldId: string): string | undefined {
    if (!this.templateInserts) {
      return null;
    }

    if (this.fieldValues) {
      return this.fieldValues[fieldId];
    }

    this.fieldValues = {};

    //  TODO: if the template's inserts changes, then this cache must be invalidated.
    for (const insert of this.templateInserts) {
      if (!insert.fieldId) {
        continue;
      }

      this.fieldValues[insert.fieldId] =
        this.inserts.find(insertReference => insertReference.insertId === insert.id)?.value;
    }

    return this.fieldValues[fieldId];
  }

  /**
   * Returns the number of signatures in the document.
   */
  getSignatureCount() {
    return this.templateInserts.filter(insert => insert.type === OfferTemplateInsertType.Signature).length;
  }
}

export class OfferBankIDSignSession {
  id: string;
  status: string;
  extendedStatus?: string;
}

export class OfferCancellation {
  canceledAt: Date;

  constructor(deriveFrom?: Partial<OfferCancellation>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }

    if (this.canceledAt) {
      this.canceledAt = new Date(this.canceledAt);
    }
  }
}

export class OfferPreviousSignature {
  signedAt: Date;
  signedDocument: {
    url: string
  };

  constructor(deriveFrom?: Partial<OfferPreviousSignature>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }

    if (this.signedAt) {
      this.signedAt = new Date(this.signedAt);
    }
  }
}

export class OfferRead {
  readAt: Date;

  constructor(deriveFrom?: Partial<OfferRead>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }

    if (this.readAt) {
      this.readAt = new Date(this.readAt);
    }
  }
}

export class OfferDecline {
  declinedAt: Date;
  message?: string;

  constructor(deriveFrom?: Partial<OfferRead>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }

    if (this.declinedAt) {
      this.declinedAt = new Date(this.declinedAt);
    }
  }
}

export class OfferDistribution {
  constructor(deriveFrom?: Partial<OfferDistribution>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }
  }
}

export class OfferMainRecipient {
  name?: string;
  sms?: string;
  email?: string;

  constructor(deriveFrom?: Partial<OfferMainRecipient>) {
    if (!deriveFrom) {
      return;
    }

    Object.assign(this, deriveFrom);

    //  Required for backend validators
    this.sms = this.sms || null;
    this.email = this.email || null;
  }
}

export type OfferInsertReference = {
  insertId: string;
  value: string;
}

export enum OfferStatus {
  Pending = 'pending',
  Distributed = 'distributed',
  Read = 'read',
  Signed = 'signed',
  Canceled = 'canceled',
  Declined = 'declined',
  Expired = 'expired'
}

export function translateOfferStatus(status: OfferStatus): string {
  const translations = {
    [OfferStatus.Read]: text`Öppnat`,
    [OfferStatus.Pending]: text`Ej skickat`,
    [OfferStatus.Distributed]: text`Skickat, oöppnat`,
    [OfferStatus.Signed]: text`Signerat`,
    [OfferStatus.Declined]: text`Avböjt`,
    [OfferStatus.Canceled]: text`Annullerat`,
    [OfferStatus.Expired]: text`Förfallit`,
  };

  return translations[status] || status;
}