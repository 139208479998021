import moment from "moment";
import React, { useState } from "react";
import { formatDate } from "../../../utils/date";
import { Button } from "../Button/Button";
import { TextInput, TextInputProps } from "../field/TextInput";
import { PopoverProps } from "../popover";
import { DatePopover } from "./DatePopover";
import { text } from "../../../utils/i18n";

type Props = Omit<TextInputProps, 'value' | 'onChange'>
  & Pick<PopoverProps, 'popoverId' | 'usePortal' | 'verticalPosition' | 'horizontalPosition' | 'parentPopoverId'>
  & {
    getInputValue?: (date: Date, original: string) => string;
    onDateValueChange: (date: Date) => void;
    clearable?: boolean;
    makeReadable?: boolean;
    time?: boolean;
    value?: Date | string;
  };

/**
 * Allows for selecting a date.
 */
export const DateSelect: React.FC<Props> = ({
  getInputValue,
  onDateValueChange,
  clearable = true,
  makeReadable = true,
  time = false,
  value,
  popoverId,
  parentPopoverId,
  usePortal,
  horizontalPosition,
  verticalPosition,
  ...props
}) => {
  let valueAsDate: Date | null = value ? new Date(value) : null;
  if (!valueAsDate?.getTime()) {
    valueAsDate = null;
  }

  let inputValue = valueAsDate ? formatDate(valueAsDate, { time: time ? 'auto' : false }) : "";
  if (!time && makeReadable && valueAsDate && moment(valueAsDate).format('L') === moment().format('L')) {
    inputValue = `${inputValue}, ${text`idag`}`;
  }

  if (typeof getInputValue === 'function' && valueAsDate) {
    inputValue = getInputValue(valueAsDate, inputValue);
  }

  const [isOpen, setOpen] = useState(false);

  return (
    <div style={{ position: 'relative' }} data-keep-popover-open>
      <TextInput
        {...props}
        onFocusCapture={() => {
          if (!props.readOnly) {
            setOpen(true);
          }
        }}
        readOnly
        value={inputValue}
        renderEnd={value && !props.readOnly && clearable && !props.disabled ? (
          <Button
            className="clear"
            icon="times"
            weight="dimmed"
            onClick={() => onDateValueChange(null)} />
        ) : null}
      />

      <div style={{ position: 'absolute', left: '50%', top: '50%' }}>
        <DatePopover
          open={isOpen}
          onClose={() => setOpen(false)}
          usePortal={usePortal}
          popoverId={popoverId}
          parentPopoverId={parentPopoverId}
          value={valueAsDate}
          onChange={value => {
            onDateValueChange?.(value);

            if (!time) {
              //  If time is selectable, the user might want to change it as well before closing the popover.
              setOpen(false);
            }
          }}
          horizontalPosition={horizontalPosition}
          verticalPosition={verticalPosition}
          time={time}
        />
      </div>
    </div>
  );
}