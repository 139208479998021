import React, { CSSProperties } from 'react';
import './styles.scss'
import cls from 'classnames'
import { eq } from '../../../utils';
import { createPortal } from 'react-dom';

interface Props {
  type?: 'modal' | 'large' | 'normal' | 'tiny' | 'xtiny';
  style?: CSSProperties;

  /**
   * Uses position absolute to center.
   */
  center?: boolean;

  className?: string;

  /**
   * Whether to run a mount animation.
   */
  animated?: boolean;
}

/**
 * Default task indicator with different configurations.
 * @author Johan Svensson
 */
const TaskIndicator = React.memo<Props>(({
  center,
  className,
  style,
  animated = false,
  type = 'normal'
}) => {
  let largeSpinner = ['modal', 'large'].includes(type);

  let Indicator = () => (
    <div style={style} className={cls([
      cls({
        "task-indicator": true,
        "tiny": type === "tiny",
        "xtiny": type === "xtiny",
        "large": largeSpinner,
        "absolute-center": center,
        "animated": animated
      }),
      className
    ])}>
      <div
        data-spinner
        style={{
          animationDelay: `-${Date.now() - 1675335008910}ms`, // All task indicators are spinning in sync.
        }}></div>
    </div>
  )

  if (type === "modal") {
    return createPortal((
      <div className="task-indicator-modal">
        <Indicator />
      </div>
    ), document.body);
  }

  return (
    <Indicator />
  )
}, eq);

export default TaskIndicator;