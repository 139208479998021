import { eq } from "../utils";

export type AttachmentPagsSelectValue = "first" | "last" | "specific";

/**
 * Attachment configuration for an offer template.
 */
export class AttachmentConfiguration {

  /**
   * See s2-offers-api for documentation.
   */
  readonly pages: number[] = [];

  /**
   * Returns a value which can be used in Select components.
   */
  getSelectValue(): AttachmentPagsSelectValue {
    if (!this.pages.length || eq(this.pages, [-1])) {
      return "last";
    }

    if (eq(this.pages, [1])) {
      return "first";
    }

    return "specific";
  }

  constructor(deriveFrom?: Partial<AttachmentConfiguration>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }
  }
}