import React, { ReactChild, ReactNode, forwardRef, useContext, useEffect, useRef, useState } from "react";
import cls from "classnames";
import { TooltipPosition, TooltipProps } from "./Tooltip";
import { HideTooltip, TooltipContext } from "./TooltipContextProvider";
import { isElementOverflowing } from "../../../utils/dom";
import styles from "./Tooltip.module.scss";

export type TooltipTriggerProps = {
  tooltip: ReactNode;
  position?: TooltipPosition;
  interactive?: 'clickable' | 'hoverable';

  /**
   * Which direction to negate the padding when `interactive` is set.
   */
  negateInteractivePadding?: 'left' | 'right';

  /**
   * Whether to convert `tooltip` `"\n"` to line breaks.
   */
  splitOnNewline?: boolean;

  /**
   * Whether to delay the tooltip.
   * @default true
   */
  delay?: boolean;
  enabled?: boolean;
  /**
   * With this you can control whether the tooltip is visible or not.
   * If unset, then hover and blur events control it instead.
   */
  visible?: boolean;

  /**
   * An optional selector for an element.
   * If the selector matches an element, and the element is overflowing
   * (for example, text overflows to ellipsis), then and only then will the tooltip be visible.
   */
  overflowingElementSelector?: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  & Pick<TooltipProps, 'fixWidth'>;

/**
 * Renders a div which shows a tooltip.
 */
export const TooltipTrigger = forwardRef<HTMLDivElement, TooltipTriggerProps>((
  {
    tooltip, position, interactive, delay, visible: visibleControl,
    fixWidth, enabled = true, overflowingElementSelector, negateInteractivePadding,
    splitOnNewline, ...props
  }, ref
) => {
  position = position || 'bottom';
  delay = delay ?? true;

  const [isVisible, setVisible] = useState(false);
  const checkHoverIntervalId = useRef(null);
  const hideTooltip = useRef<HideTooltip>(null);
  const elementRef = useRef<HTMLDivElement>(null);
  const context = useContext(TooltipContext);
  const location = useRef<{ left: number; top: number; }>({ left: 0, top: 0 });

  if (splitOnNewline && typeof tooltip === 'string') {
    tooltip = (
      <div>{tooltip.split(/\n|\\n/g).map(line => (<>{line}<br/></>))}</div>
    );
  }

  useEffect(() => {
    return () => {
      //  Hide the tooltip on unmount.
      hideTooltip.current?.();
    };
  }, []);

  useEffect(() => {
    if (typeof visibleControl === 'boolean') {
      setVisible(visibleControl);
    }
  }, [visibleControl]);

  if (!context) {
    console.warn("TooltipTrigger rendered outside a TooltipContextProvider");
  }

  const stringValue = typeof tooltip === 'string' ? tooltip : null;

  /**
   * Checks whether the container is still being hovered. If not,
   * then the tooltip will be hidden.
   */
  function checkIsHover(element: HTMLElement) {
    if (element && !element.matches?.(':hover') && typeof visibleControl !== 'boolean') {
      setVisible(false);
    }
  }

  useEffect(() => {
    clearInterval(checkHoverIntervalId.current);

    //  If the tooltip changes, show the tooltip again.
    if (isVisible) {
      hideTooltip.current = context?.show({
        children: tooltip,
        position,
        delay,
        left: location.current.left,
        top: location.current.top,
        fixWidth
      });

      checkIsHover(elementRef.current);
      checkHoverIntervalId.current = setInterval(() => checkIsHover(elementRef.current), 1000);
    } else {
      hideTooltip.current?.();
    }
  }, [stringValue, isVisible]);

  if (!enabled) {
    //  Worked like this in the previous version of this component, not sure if it's optimal though.
    return props.children as any;
  }

  return (
    <div
      {...props}
      className={cls([props.className, styles.trigger, "tooltip-trigger"])} // tooltip-trigger is a legacy class name.
      data-negate-interactive-padding={negateInteractivePadding ?? undefined}
      ref={element => {
        elementRef.current = element;

        if (typeof ref === 'function') {
          ref(element);
        }

        if (!element) {
          return;
        }

        const bounds = element.getBoundingClientRect();

        location.current.left = bounds.left + bounds.width / 2;
        location.current.top = bounds.top + bounds.height / 2;

        if (position.includes('right')) {
          location.current.left = bounds.right;
        } else if (position.includes('left')) {
          location.current.left = bounds.left;
        }
      }}
      data-interactive={interactive}
      onMouseLeave={() => {
        if (typeof visibleControl !== 'boolean') {
          setVisible(false);
        }
      }}
      onMouseEnter={event => {
        props.onMouseEnter?.(event);

        if (!enabled || !tooltip) {
          return;
        }

        if (overflowingElementSelector &&
          !isElementOverflowing(document.querySelector(overflowingElementSelector))) {
          return;
        }

        setVisible(true);
      }}>

    </div>
  )
});