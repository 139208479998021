/**
 * Represents a single document comment.
 */
export default class Comment {
  id: string;
  userId: string;
  date = new Date();
  message: string;

  constructor(deriveFrom?: Partial<Comment>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }

    if (this.date) {
      this.date = new Date(this.date);
    }
  }
}