import React from "react";

/**
 * Represents a simple file to be shown in a file list.
 * @author Johan Svensson
 */
export default class UploadedFile {
  /**
   * Creates an instance with metadata from a file.
   * This is meant to represent the file before it has been uploaded,
   * hence the instance does not have an `id` or `uri` assigned.
   */
  static fromFile(file: File, id: string | null = null): UploadedFile {
    return {
      id,
      name: file.name,
      uri: null,
      mimetype: file.type,
      file
    };
  }

  id: any;
  name: string;
  uri: string;
  mimetype?: string;
  file?: File;
  width?: number;
  height?: number;
}

export class ListableFile extends UploadedFile {
  description?: React.ReactChild;
  disabled?: boolean;
  /**
   * @default true
   */
  deletable?: boolean;
}