import * as meta from "../../AppType";

/**
 * A list of hostnames (ascii) per organization.
 * Allows an organization to retrieve all configs for that organization, for example to link another express flow.
 * @see https://www.punycoder.com/ For ascii encoding a hostname.
 */
export const hostnamesPerOrganization: {
  [organizationId: string]: string[]
} = {
  "62b468341a17a80028838e7f": ["xn--slj-qla.smultronmobil.se", "xn--sljnya-bua.smultronmobil.se"], // prod: SPG Telecom AB
  "63b4a856238fb9e83bf68961": ["xn--slj-qla.salesys-next.elipt.is", "sales.oceanlive.elipt.is"], // dev: Snabbflöde test
  "6394ba8054fee3c95e67eaff": ["sales.oceanlive.se", "salesys.oceanlive.se"], // prod: OceanLive
  "63ca4fd7dbfeb2c09e148931": ["xn--slj-qla.idnor.se", "salesys.idnor.se", "salesys.idnor.no"], // prod: IDNOR
  "6479802d003393dc7e26b8e7": ["salesys.protectconnect.se"], // prod: Protect & Connect
  "64d4b9345e2047b1e24f0323": ["salesys.baerumenergi.no"], // prod: Baerum Energi
  "6567c1a7ed4625364df83b55": ["salesys.greenhero.com"], // prod: Green Hero AB
  "661bdfd5b547c83416d0e7c7": ["salesys.kildenkraft.no"], // prod: Kilden Kraft AS
  "6618ff4d4933e107fa37b034": ["salesys.tryggsurf.se"], // prod: Trygg Sverige Holding AB
};

/**
 * @param hostname ASCII hostname.
 * @returns Whether an express flow config is associated with `hostname`.
 */
export function hasExpressFlowConfig(hostname = meta.hostname) {
  return Boolean(Object.values(hostnamesPerOrganization).find(hostnames => hostnames.includes(hostname)));
}

/**
 * @returns Returns a list of (ascii encoded) express flow hostnames associated with an organization ID.
 */
export function listExpressFlowHostnames(organizationId: string): string[] {
  return hostnamesPerOrganization[organizationId] || [];
}