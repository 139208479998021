import { text } from "../utils/i18n";

export type ResolveUrlParams = {
  offerId: string;
}

export class SignedOfferLink {
  /**
   * Optional IDs of one or more after-order work actions to grab a link from.
   * 
   * If multiple IDs are provided, then the link of the first action to be executed will be used.
   */
  readonly afterOrderWorkActionIds: string[] = [];
  readonly url?: string = "https://salesys.se";
  readonly urlObject: URL | null = null;
  readonly linkText?: string;
  /**
   * Whether the redirect is delayed to show the sign confirmation.
   */
  readonly isDelayedRedirect: boolean = true;
  readonly isAutomaticRedirect: boolean = false;

  /**
   * Optional message. Overrides the default message,
   */
  readonly message: string = text`Avtalet är signerat`;

  constructor(deriveFrom?: Partial<SignedOfferLink>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }

    this.urlObject = null;
    
    if (this.url) {
      try {
        this.urlObject = new URL(/^https?:\/\//.test(this.url) ? this.url : "https://" + this.url);
      } catch (error) {
        console.error(error);
      }
    }
  }

  /**
   * Returns a URL with query parameters.
   * 
   * Placeholders are replaced with values from `params`.
   * The name of the object property should be encapsulated in brackets, for example:
   * `https://salesys.se?reference=[offerId]`.
   */
  resolveUrl(params: ResolveUrlParams): URL | null {
    if (!this.url) {
      return null;
    }

    let url = this.url;
    if (!/^https?\:/i.test(url)) {
      url = 'https://' + url;
    }

    const originalUrl = url;
    const placeholdersRegexp = /\[(\w+)\]/ig;

    let match: RegExpExecArray = null;
    while (match = placeholdersRegexp.exec(originalUrl)) {
      const key = match[1];

      let value = params[key] || '';
      if (typeof value !== 'string') {
        value = '';
      }

      //  Replace placeholder.
      url = url.replace(match[0], encodeURIComponent(value));
    }

    try {
      return new URL(url);
    } catch (error) {
      console.error(error);
      return new URL(this.url);
    }
  }
}

/**
 * Links for navigating away from an offer of a status other than Pending and Read (terminative statuses). 
 */
export class OfferStatusLinks {
  readonly signed: SignedOfferLink = new SignedOfferLink();
  readonly defaultUrl?: string;

  constructor(deriveFrom?: Partial<OfferStatusLinks>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }

    if (this.signed) {
      this.signed = new SignedOfferLink(this.signed);
    }
  }

  getDefaultUrl(): URL {
    let { defaultUrl, signed } = this;
    let signedUrl = signed?.url;

    try {
      if (defaultUrl && !/^https?:\/\//.test(defaultUrl)) {
        defaultUrl = 'https://' + defaultUrl;
      }

      if (defaultUrl) {
        return new URL(defaultUrl);
      }

      if (!signed?.afterOrderWorkActionIds.length) {
        //  Don't use a customer-specific link, created by an after-order work action.
        if (signedUrl && !/^https?:\/\//.test(signedUrl)) {
          signedUrl = 'https://' + signedUrl;
        }

        if (signedUrl) {
          const signedUrlObject = new URL(signedUrl);
          return new URL(signedUrlObject.origin);
        }
      }
    } catch (error) {
      console.error(error);
    }

    return new URL("https://salesys.se");
  }
}