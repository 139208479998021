import React from 'react'
import classNames from 'classnames';
import { eq } from '../../../utils';
import './styles.scss';
import { getDataAttributes } from '../../../utils/dom';

export type FAIconType = 'regular' | 'solid';

export type FontAwesomeIconProps = {
  name: string;
  type?: FAIconType;
  className?: string;

  /**
   * Size in px.
   */
  size?: number;
  style?: React.CSSProperties;

  /**
   * Shether to apply an interactive style.
   */
  interactive?: boolean;
}

/**
 * Renders a single Font Awesome icon.
 * @author Johan Svensson
 */
const FontAwesomeIcon = React.memo((props: FontAwesomeIconProps) => {
  var { name, type, size } = props
  if (typeof type !== "string") {
    type = "solid"
  }

  return (
    <i
      className={classNames([
        classNames({
          "icon": true,
          "far": type === "regular",
          "fas": type === "solid",
          "interactive": props.interactive,
          [`fa-${name}`]: true
        }),
        props.className
      ])}
      style={{ fontSize: props.size, ...props.style }}
      data-name={props.name}
      {...getDataAttributes(props)}>
    </i>
  );
}, eq);

export default FontAwesomeIcon;