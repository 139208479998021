import React from 'react';
import FontAwesomeIcon from '../fa_icon';
import styles from './error_screen_wrapper.module.scss';
import { text } from '../../../utils/i18n';

type Props = {
  title?: string;
}

/**
 * Contact information for support. Intended in addition with error info.
 * @author Johan Svensson
 */
export default function SupportContact({
  title = text`Kontakta vår support om du har frågor.`
}: Props) {
  return (
    <div className={styles.support}>
      <p>{title}</p>
      <p className={styles.phone}><FontAwesomeIcon name="phone" className={styles.icon} /> 060-607 01 77</p>
    </div>
  )
}