import React, { CSSProperties, useRef } from 'react';
import moment from 'moment';
import { TextInput } from '../../../../core/field/TextInput';
import styles from "./TimeSelect.module.scss";

type Props = {
  value?: Date;
  onChange?: (newValue: Date) => void;
  onBlurChange?: (newValue: Date) => void;

  minuteTestId?: string;
  hourTestId?: string;
  readOnly?: boolean;
  inputStyle?: CSSProperties;
}

/**
 * Allows for editing hours and minutes.
 * @author Johan Svensson
 */
export const TimeInput: React.FC<Props> = ({
  hourTestId, inputStyle, minuteTestId, onBlurChange, onChange, readOnly, value
}) => {
  const buffer = useRef<Date>(value);
  buffer.current = value;

  //  Prevents onBlur from being invoked when switching focus between contained inputs.
  const invokeOnBlurTimeout = useRef(null);

  const delayBlur = () => {
    if (isNaN(buffer.current?.getTime())) {
      //  Can get "Invalid Date" if value is null.
      return;
    }

    invokeOnBlurTimeout.current = setTimeout(() => onBlurChange?.(buffer.current), 16);
  };

  const cancelBlur = () => clearTimeout(invokeOnBlurTimeout.current);

  return (
    <div className={styles.container}>
      <div>
        <Input
          value={value?.getHours()}
          onChange={hour => {
            if (hour != null) {
              onChange?.(buffer.current = moment(buffer.current).hour(hour).toDate());
            }
          }}
          onBlur={hour => {
            if (hour != null) {
              buffer.current = moment(buffer.current).hour(hour).toDate();
              delayBlur();
            }
          }}
          onFocus={cancelBlur}
          min={0}
          max={23}
          testId={hourTestId}
          readOnly={readOnly}
          style={inputStyle}
        />
      </div>
      <div>
        <p className={styles.separator}>:</p>
      </div>
      <div>
        <Input
          value={value?.getMinutes()}
          onChange={minute => {
            if (minute != null) {
              onChange?.(buffer.current = moment(buffer.current).minute(minute).toDate());
            }
          }}
          onBlur={minute => {
            if (minute != null) {
              buffer.current = moment(buffer.current).minute(minute).toDate();
              delayBlur();
            }
          }}
          onFocus={cancelBlur}
          min={0}
          max={59}
          pad
          testId={minuteTestId}
          readOnly={readOnly}
          style={inputStyle}
        />
      </div>
    </div>
  )
}

type InputProps = {
  style?: CSSProperties;
  value: number;
  onChange: (newValue: number) => void;
  onBlur?: (value: number) => void;
  onFocus?: () => void;
  max?: number;
  min?: number;
  pad?: boolean;
  testId?: string;
  readOnly?: boolean;
};

const Input: React.FC<InputProps> = (props) => {
  return (
    <TextInput
      style={props.style}
      className={styles.input}
      allow="numeric"
      value={props.value}
      onNumericValueChange={props.onChange}
      onBlur={e => props.onBlur?.(Number(e.currentTarget.value))}
      onFocus={props.onFocus}
      maxLength={2}
      max={props.max}
      min={props.min}
      formatBlurredValue={props.pad ? value => (
        String(value).padStart(2, '0')
      ) : null}
      testId={props.testId}
      readOnly={props.readOnly}
    />
  )
}