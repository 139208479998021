import uuid from "uuid";

/**
 * @returns A temporary, unique ID.
 */
export function createTemporaryId() {
  return `tmp-${uuid()}`;
}

/**
 * @param id ID of the entity.
 * @returns Whether the entity with the given ID has been created.
 */
export function isCreated(entityId: string): boolean {
  if (!entityId || /tmp-/i.test(entityId)) {
    return false;
  }
  return true;
}