import { Browser, detect } from "detect-browser";

const os = detect();

/**
 * Whether the current user is on a mobile phone or tablet.
 */
export const isMobile = os.name === "android" || os.name === "ios";

/**
 * Returns a friendly browser name.
 */
export function getBrowserName(browser = detect().name, fallback: string | null | undefined = "Webbläsaren"): string {
  return {
    ['edge' as Browser]: 'Edge',
    ['edge-ios' as Browser]: 'Edge',
    ['edge-chromium' as Browser]: 'Edge',
    ['safari' as Browser]: 'Safari',
    ['ios' as Browser]: 'Safari',
    ['chrome' as Browser]: 'Chrome',
    ['firefox' as Browser]: 'Firefox',
    ['ie' as Browser]: 'Internet Explorer',
    ['crios' as Browser]: 'Chrome',
  }[browser] || fallback || browser;
}